<template>
  <section class="mobile-apps-section">
    <div class="img-box">
      <img v-lazy="'/img/apps-image.jpg'" width="562" height="615" alt="">
    </div>

    <v-container>
      <v-row justify="end">
        <v-col cols="12" md="7" class="pt-0 pb-0">
          <div class="section-content">
            <h2 class="section-title">No laptop? No problem.</h2>
            <h4 class="section-desc">For those planning on travelling light this vacation, we've got you.
              We've created a super easy to use app, where you can:</h4>

            <ul class="features-list">
              <li>
                <img class="icon" v-lazy="'/img/icons/fa-check-circle.svg'" height="15" width="15" alt="">
                Find plane ticket deals
              </li>
              <li>
                <img class="icon" v-lazy="'/img/icons/fa-check-circle.svg'" height="15" width="15" alt="">
                Book accommodation , activities, and tours
              </li>
              <li>
                <img class="icon" v-lazy="'/img/icons/fa-check-circle.svg'" height="15" width="15"  alt="">
                Manage your bookings
              </li>
              <li>
                <img class="icon" v-lazy="'/img/icons/fa-check-circle.svg'" height="15" width="15" alt="">
                Leave reviews
              </li>
              <li>
                <img class="icon" v-lazy="'/img/icons/fa-check-circle.svg'" height="15" width="15" alt="">
                Find tips on ways to travel cheap and how to get the most out of your stay
              </li>
            </ul>

            <div class="download-buttons">
              <v-btn @click="goTo($config.iosAppLink)" outlined x-large color="primary" class="search-button">
                <img v-lazy="'/img/icons/fa-app-store-ios.svg'" width="32" height="37" alt="iOS app">
                App Store
              </v-btn>
              <v-btn @click="goTo($config.androidAppLink)" outlined x-large color="primary" class="search-button">
                <img v-lazy="'/img/icons/fa-google-play.svg'" width="32" height="32" alt="Android App">
                Play Market
              </v-btn>
            </div>

            <div class="addition-info">
              <div class="text">
                Oh, and while you're here...<br>
                <strong>Follow us on our social medias @{{ $texts.brand_slug }}</strong> for the latest travel news, unique travel
                destinations, and activity ideas!
              </div>
              <div class="social-links">
                <a :href="$config.facebookLink" target="_blank" class="link">
                  <img v-lazy="'/img/icons/fa-facebook-f.svg'" width="10" alt="">
                </a>
                <a :href="$config.twitterLink" target="_blank" class="link">
                  <img class="twitter" v-lazy="'/img/icons/fa-twitter.svg'" width="14" alt="">
                </a>
                <a :href="$config.instagramLink" target="_blank" class="link">
                  <img class="ig" v-lazy="'/img/icons/fa-instagram.svg'" width="13" alt="">
                </a>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

export default {
  data: () => ({

  }),
  methods: {
    goTo(url) {
      window.open(url, '_blank')
    },
  }
}
</script>

<style src="./mobile-apps.scss" lang="scss"></style>
