<template>
  <div class="popular-destinations">
    <div class="container">
      <div class="section-title">
        <h2>Top destinations</h2>
      </div>
      <div>
        <div>
          <ul class="cities-tabs-header desktop">
            <li v-for="(city, index) in cities" :class="{'active' : currentTab === index}" :key="index" @click="currentTab = index">
              <img v-lazy="'/img/re-popular-cities/icons/'+ city.iconUrl" :alt="city.name" :width="city.iconSizes[0]" :height="city.iconSizes[1]">
              {{city.name}}
            </li>
          </ul>
          <v-select
            class="cities-tabs-header mobile"
            outlined
            height="40"
            v-model="selectedCity"
            :items="cities"
            hide-details
            label="Top Destinations"
          >
            <template v-slot:selection="{ item }">
              <img class="mr-2" v-lazy="'/img/re-popular-cities/icons/'+ item.iconUrl" :width="item.iconSizes[0]" :height="item.iconSizes[1]"  alt="">
              {{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              <img class="mr-2" v-lazy="'/img/re-popular-cities/icons/'+ item.iconUrl" :width="item.iconSizes[0]" :height="item.iconSizes[1]"  alt="">
              {{item.name}}
            </template>
          </v-select>
          <div class="cities-tabs-body">
            <div class="city-info" @click="goToUrl(`/attractions/${currentCity.code}`, false)">
              <div>
                <img
                  class="mr-2"
                  v-lazy="'/img/re-popular-cities/'+ nameToImgUrl(currentCity.name) + ( $store.state.isWebp ? '.webp' : '.jpg')"
                  width="586"
                  height="340"
                  alt=""
                >
              </div>
              <div class="city-info__data">
                <h3>{{currentCity.name}}</h3>
                <div class="city-info__description" v-html="currentCity.description"></div>
                <ul class="city-info__categories">
                  <li>
                    <a :href="'/attractions/' + currentCity.code + '?categories=new-activities'">
                      <img v-lazy="'/img/re-popular-cities/category-icons/activities.svg'" width="24" height="24" alt="">
                      Activities
                    </a>
                  </li>
                  <li>
                    <a :href="'/attractions/' +currentCity.code + '?categories=new-attractions-guided-tours'">
                      <img v-lazy="'/img/re-popular-cities/category-icons/attractions.svg'" width="24" height="24" alt="">
                      Attractions & guide tour
                    </a>
                  </li>
                  <li>
                    <a :href="'/attractions/' +currentCity.code + '?categories=new-excursions-day-trips'">
                      <img v-lazy="'/img/re-popular-cities/category-icons/excursions.svg'" width="24" height="24" alt="">
                      Excursions & day trips
                    </a>
                  </li>
                  <li>
                    <a :href="'/attractions/' +currentCity.code + '?categories=new-tickets-events'">
                      <img v-lazy="'/img/re-popular-cities/category-icons/tickets.svg'" width="24" height="24" alt="">
                      Tickets and events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="city-tours" v-if="popularTours.length">
              <h2 class="city-top-header">top tours in {{currentCity.name}}</h2>
              <div class="city-tours-wrapper desktop" :class="{'city-tours-wrapper--short': popularTours.length < 3}">
                <div class="city-tour" v-for="tour in popularTours" :key="tour.id" @click="goToUrl(`/attractions/${currentCity.code}/${tour.id}-${$slug(tour.name)}`)">
                  <img
                    v-lazy="createImgObj(tour.cover_image_url || tour.images[0], null)"
                    v-if="tour.cover_image_url || (tour.images && tour.images.length)"
                    width="586"
                    height="340"
                    :alt="currentCity.name">
                  <img v-lazy="createImgObj(tour.photos[0].url, null)" v-else-if="tour.photos && tour.photos.length" :alt="currentCity.name">
                  <div class="city-tour__body">
                    <div class="city-tour__row">
                      <div>
                        <h4>{{tour.name}}</h4>
                        <div class="city-tour__location" v-if="tour.location.address_info && tour.location.address_info.length">
                          <img v-lazy="'img/icons/fa-map-market-alt.svg'" height="14" width="16" alt="">
                          {{tour.location.address_info}}
                        </div>
                        <div class="city-categories-wrapper" v-if="tour.categories">
                            <div class="city-categories"></div>
                        </div>
                      </div>
                      <div>
                        <review-rating v-if="tour.avg_rating" :star-size=[8,9] :rating="tour.avg_rating" :ratingClass="getReviewClass(tour.avg_rating)" class="rating"/>
                      </div>
                    </div>
                    <div>
                      {{(tour.short_description || tour.description) | trim}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="city-tours-wrapper mobile" v-if="popularTours.length">
                <client-only>
                  <carousel
                    v-if="isNuxtReady"
                    :nav="false"
                    :items="1"
                    :center="true"
                  >
                    <div class="slide" v-for="tour in popularTours" :key="tour.id">
                      <div class="city-tour"
                           @click="goToUrl(`/attractions/${currentCity.code}/${tour.id}-${$slug(tour.name)}`)">
                        <img
                          v-lazy="createImgObj(tour.cover_image_url || tour.images[0], null)"
                          v-if="tour.cover_image_url || (tour.images && tour.images.length)"
                          alt="">
                        <img v-lazy="createImgObj(tour.photos[0].url, null)" v-else-if="tour.photos && tour.photos.length" alt="">
                        <div class="city-tour__body">
                          <div class="city-tour__row">
                            <div>
                              <h4>{{tour.name}}</h4>
                              <div class="city-tour__location" v-if="tour.location.address_info && tour.location.address_info.length">
                                <img v-lazy="'img/icons/fa-map-market-alt.svg'" height="14" width="16" alt="">
                                {{tour.location.address_info}}
                              </div>
                              <div class="city-categories-wrapper" v-if="tour.categories">
                                <div class="city-categories"></div>
                              </div>
                            </div>
                            <div>
                              <review-rating
                                v-if="tour.avg_rating"
                                :rating="tour.avg_rating"
                                :star-size=[8,9]
                                :ratingClass="getReviewClass(tour.avg_rating)"
                                class="rating"
                              />
                            </div>
                          </div>
                          <div>
                            {{(tour.short_description || tour.description) | trim}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </carousel>
                </client-only>
              </div>
            </div>
            <div class="city-attractions" v-if="popularAttractions.length">
              <h2 class="city-top-header">top Attractions in {{currentCity.name}}</h2>
              <div class="local-attractions desktop" :class="{'local-attractions--short': popularAttractions.length < 3}">
                <div class="local-attraction" v-for="(product, index) in popularAttractions" :key="index">
                  <review-rating
                    v-if="product.rating"
                    :rating="product.rating"
                    :star-size=[8,9]
                    :ratingClass="getReviewClass(product.rating)"
                    class="rating"
                  />
                  <div class="local-attraction__container la-hover" @click="goToUrl(product.url)">
                    <div class="local-attraction__header">{{product.name}}</div>
                    <div class="local-attraction__location">
                      <FontAwesomeIcon :icon="['far', 'location-dot']"/>
                      <span>{{product.location}}</span>
                    </div>
                    <div class="local-attraction__price" v-if="product.price">
                      <span>from</span>
                      <b>{{product.price.formatted}}</b>
                    </div>
                    <p class="la-hover__description">
                      {{product.description | trim}}
                    </p>
                    <a :href="product.url"
                      target="blank"
                      class="la-hover__link"
                    >
                      See Details
                      <img  src="~/assets/img/arrow-left.svg" width="19" height="18" alt="">
                    </a>
                  </div>

                  <img class="local-attraction__bg" v-lazy="product.imgUrl" alt=""/>
                  <div class="local-attraction__gradient"></div>
                </div>
              </div>
              <div class="local-attractions mobile" v-if="popularAttractions.length">
                <client-only>
                  <carousel
                    v-if="isNuxtReady"
                    :nav="false"
                    :items="1"
                    :center="true"
                  >
                    <div class="slide" v-for="(product, index) in popularAttractions" :key="index">
                      <div class="local-attraction">
                        <review-rating v-if="product.rating" :star-size=[8,9] :rating="product.rating" :ratingClass="getReviewClass(product.rating)" class="rating"/>
                        <div class="local-attraction__container la-hover" @click="goToUrl(product.url)">
                          <div class="local-attraction__header">{{product.name}}</div>
                          <div class="local-attraction__location">
                            <client-only>
                              <FontAwesomeIcon :icon="['far', 'location-dot']"/>
                            </client-only>
                            <span>{{product.location}}</span>
                          </div>
                          <div class="local-attraction__price" v-if="product.price">
                            <span>from</span>
                            <b>{{product.price.formatted}}</b>
                          </div>
                          <p class="la-hover__description">
                            {{product.description | trim}}
                          </p>
                          <a :href="product.url"
                              target="blank"
                              class="la-hover__link"
                          >
                            See Details
                            <img src="~/assets/img/arrow-left.svg" alt="">
                          </a>
                        </div>

                        <img class="local-attraction__bg" v-lazy="product.imgUrl" alt=""/>
                        <div class="local-attraction__gradient"></div>
                      </div>
                    </div>
                  </carousel>
                </client-only>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="more-btn-wrapper">
        <v-btn
          outlined
          block
          x-large
          color="primary"
          class="more-articles-btn"
          target="_blank"
          :href="'/attractions/'+currentCity.code + '?sort_by=rating_desc'"
        >See more about {{currentCity.name}}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewRating from '@/components/ReviewRating/ReviewRating'
import cities from '@/mixins/popular-cities.js'
import images from '@/mixins/images.js'
import reviewClass from '@/mixins/review-class.js'

const carousel = () => window && window !== undefined ? import('vue-owl-carousel') : null


export default {
  mixins: [cities, images, reviewClass],
  data() {
    return {
      isNuxtReady: false,
      currentTab: 0,
      selectedCity: null,
      popularTours: [],
      popularAttractions: [],
    }
  },
  async mounted() {
    if (process.browser) {
      window.onNuxtReady(app => {
        this.isNuxtReady = true
      })
    }
    this.selectedCity = this.cities[this.currentTab]
    const placeId = this.cities[this.currentTab].place_id
    this.getTopTours(placeId)
    this.getTopAttractions(placeId)
  },
  computed: {
    siteHost() {
      return this.$store.state.siteHost
    },
    currentCity() {
      return this.cities[this.currentTab]
    }
  },
  watch: {
    currentTab(val) {
      const placeId = this.currentCity.place_id
      this.getTopTours(placeId)
      this.getTopAttractions(placeId)
    },
    selectedCity(city) {
      this.currentTab = this.cities.indexOf(city)
    }
  },
  filters: {
    trim: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 100) + '...'
    }
  },
  methods: {
    isItWebp() {
      return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0
    },
    createImgObj(url, errorUrl){
      return {
        src: this.imageUrlWithProxy(url, 586, 340),
        error: errorUrl ? errorUrl : '/img/no-photo.svg',
        loading: '/img/no-photo.svg'
      }
    },
    nameToImgUrl(name) {
      return name.trim().toLowerCase().split(' ').join('-')
    },
    goToUrl(url, newWindow = true) {
      let routeData = this.$router.resolve(url)

      window.open(routeData.href, newWindow ? '_blank' : '_self')
    },
    async getTopTours(place_id) {
      const paramsTours = {
        place_id: place_id,
        size: 3,
        sort_by: 'rating_desc',
        categories: 'new-attractions-guided-tours'
      }
      this.popularTours = await this.$axios.get('/api/product/v1/activities', {params: paramsTours}).then( res => res.data.activities )
    },
    async getTopAttractions(place_id) {

      const paramsAttractions = {
        place_id: place_id,
        size: 3,
        sort_by: 'rating_desc'
      }

      this.popularAttractions = await this.$axios.get('/api/product/v3/activities', {params: paramsAttractions}).then( res => {
        let products = []
        res.data.activities.map(product => {

          products.push({
            url: `/attractions/${this.currentCity.code}/${product.id}-${this.$slug(product.name)}`,
            imgUrl: {
              src: product.cover_image_url ? this.imageUrlWithProxy(product.cover_image_url, 384, 290) : this.imageUrlWithProxy(product.images?.[0], 384, 290),
              error: '/img/no-photo.svg',
              loading: '/img/no-photo.svg'
            },
            description: product.description,
            name: product.name,
            location: product.location && product.location.city_name && product.location.country_code
              ? `${product.location.city_name}, ${product.location.country_code}`
              : '',
            price: product.price_from,
            rating: product.avg_rating ? product.avg_rating.toFixed(2) : 0
          })
        })
        return products
      })
    },
    searchProducts(slug) {
      this.$store.commit('CLEAR_PRODUCTS', [])
      this.$store.commit('SET_SELECTED_CITY', {})
      this.$store.commit('SET_FULL_TEXT_SEARCH', '')
      this.$router.push({path: `/attractions/${slug}`})
    },
  },
  jsonld() {
    const cities = this.cities.map((item, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'url': `${this.siteHost}/attractions/${item.code}`
    }))

    return {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      'itemListElement': cities
    }
  },
  components: {
    ReviewRating,
    carousel
  }
}
</script>

<style scoped lang="scss" src="./popular-cities.scss"></style>
