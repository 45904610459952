<template>
  <div class="popular-products">
    <h2 class="popular-products-title">Popular Products</h2>
    <div class="popular-products-list">
      <template v-for="(product, index) in products">
        <div class="popular-products-item h-product" :key="index" @click="goToProduct(product.url)">
          <div class="product-img">
            <img v-lazy="product.imgUrl" :alt="product.name" width="579" height="439" class="u-photo">
          </div>
          <div class="product-info">
            <div>
              <div class="product-name p-name">{{product.name}}</div>
              <div class="product-location">{{product.location}}</div>
            </div>
            <div>
              <v-btn
                color="primary"
                outlined
                depressed
                large
                link
                :to="product.url"
              >
                From <span class="p-price">${{product.price}}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          url: '/search/attractions/fr-idf-paris/413-1d-paris-city-&-louvre-tour-incl-eiffel-tower-lunch',
          imgUrl: '/img/popular-products/eiffel-tower.webp',
          name: 'Louvre Tour Incl Eiffel Tower Lunch',
          location: 'Paris, France',
          price: 197
        },
        {
          url: '/search/attractions/us-ny-new-york/129-3h-new-york-dumbo-the-new-brooklyn-tour',
          imgUrl: '/img/popular-products/brooklyn-tour.webp',
          name: 'The New Brooklyn Tour',
          location: 'New York City, USA',
          price: 83
        },
        {
          url: '/search/attractions/ae-du-dubai/535-4h-dubai-modern-city-tour',
          imgUrl: '/img/popular-products/dubai-modern-city-tour.webp',
          name: 'Dubai Modern City Tour',
          location: 'Dubai, UAE',
          price: 21
        },
        {
          url: '/search/attractions/gb-wsm-london/750-1d-stonehenge-&-bath-tour-ex-london-incl-lunch',
          imgUrl: '/img/popular-products/stonehenge.webp',
          name: 'Stonehenge',
          location: 'London, UK',
          price: 102
        }
      ]
    }
  },
  methods: {
    goToProduct(url) {
      this.$router.push(url)
    }
  },
  jsonld() {
    const items = this.products.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: item.name,
        url: item.url,
        image: `${item.imgUrl}`,
        offers: {
          '@type': 'Offer',
          'url': item.url,
          'price': item.price,
          'priceCurrency': 'USD'
        }
      },
    }))

    return {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement: items,
    }
  }
}
</script>


<style scoped lang="scss">
  .popular-products {
    padding-top: 32px;
  }

  .popular-products-title {
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
    text-transform: capitalize;
    color: #424D63;
    margin-bottom: 32px;
    font-weight: 400;
  }

  .popular-products-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .popular-products-item {
      width: 48.5%;
      margin-bottom: 48px;
      cursor: pointer;

      .product-img {
        position: relative;
        height: 279px;
        overflow: hidden;
        margin-bottom: 10px;
        border-radius: 8px;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 33.85%, rgba(0, 0, 0, 0.5) 100%);
        }

        img {
          height: auto;
          width: 100%;
        }

        @media screen and (max-width: 1024px) {
          height: 210px;
        }
      }

      .product-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .product-name {
          font-size: 24px;
          line-height: 42px;
          font-weight: 600;
          color: #333333;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 30px;
          }
        }

        .product-location {
          font-size: 18px;
          line-height: 27px;
          text-transform: capitalize;
          color: #424D63;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }


        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
</style>
